<template>
  <v-card>
    <v-row class="mt-2 mx-2 pt-0">
      <v-col
        cols="12"
      >
        <v-select
          v-model="selectedTopUp"
          outlined
          dense
          item-value="id"
          item-text="username"
          :items="topup_list"
          label="Select TopUp"
        ></v-select>
      </v-col>
      <v-col
        cols="5"
        xs="5"
        class="text-left mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedStartDate"
          dense
          type="date"
          label="Start Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="5"
        xs="5"
        class="text-right mt-0 pt-0"
      >
        <v-text-field
          v-model="selectedEndDate"
          dense
          type="date"
          label="End Date"
        ></v-text-field>
      </v-col>
      <v-col
        cols="2"
        xs="2"
        class="text-right mt-0 pt-0"
      >
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          icon small
          class="primary"
          @click="getTransactions()"
        >
          <font-awesome-icon icon="fa-solid fa-search"/>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-title class="">
      <span class="me-3">({{ transactions.length }}) Transactions</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <h3 class="ml-3">Total Withdrawal: {{ withdrawal_total }} F CFA </h3>
    <v-data-table
      :headers="headers"
      :items="transactions"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.username`]="{item}">
        <div class="d-flex flex-column" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</span>
          <small>{{ item.campaign }}</small>
        </div>
      </template>
      <template #[`item.amount`]="{item}">
        {{ item.amount }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[item.status]"
          class="font-weight-medium"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template #[`item.date_time`]="{item}">
        {{ item.date_time }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Withdrawal: 'primary',
      Deposit: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      switch1: true,
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      transactions: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      selectedTopUp: null,
      topup_list: ["All"],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | List of Transactions"
      this.getTopUps()
  },

  methods:{
    getTopUps(){
      axios
        .get('/api/v1/manager/get/topups/')
        .then(response => {
          this.topup_list = response.data  // get the data and fill into campaigns
          console.log(this.topup_list)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async getTransactions(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (!this.selectedTopUp|| this.selectedTopUp === ''){
        this.selectedTopUp = 'All'
      }
      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/get/transactions/withdrawal/'+this.selectedTopUp+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.withdrawal_total = response.data["withdrawal_total"]
          this.transactions = response.data["response_data"]  // get the data and fill into campaigns
          console.log(this.transactions)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

  },
}
</script>
